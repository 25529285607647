import {
  PRESCRIPTION_ERROR_MORE_THAN_ONE_DEFAULT_VIEW,
  PRESCRIPTION_ERROR_NO_DEFAULT_VIEW,
} from '@/constants/ErrorConstants'
import { ItemType } from '@/constants/ItemType.enum'
import { PRESCRIPTION_TYPES } from '@/constants/PrescriptionConstants'
import db from '@/firebase/firestore'
import Functions, { ProfilesByItems } from '@/firebase/functions'
import { StepItem } from '@/models'
import AuditService from '@/service/AuditService'
import {
  getCounterCollectionName,
  getDocumentsInCollection,
  getFirstActiveDocument,
  updateRef,
} from '@/service/FirebaseService'
import LookService from '@/service/LookService'
import ProductService from '@/service/ProductService'
import { Item, itemConverter } from '@/types/Item'
import { Look } from '@/types/Look'
import { Product } from '@/types/Product'
import { Profile, profileConverter } from '@/types/Profile'
import { DocumentData, DocumentReference } from '@firebase/firestore-types'
import { FieldValue } from 'firebase/firestore'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { RootState } from '..'
import { isEmpty } from 'lodash/fp'

const auditLog = new AuditService()

type FirebaseOnSnapshot = () => void

export type PrescriptionState = {
  prescriptionRef: DocumentReference<DocumentData>
  profiles: any[]
  brands: any[]
  events: any[]
  steps: any[]
  missingPrescriptionHits: any[]
  items: any[]
  looks: any[]
  criterias: any[]
  flags: any[]
  view: {
    id: string | null
    events: any[]
    steps: ViewStep[]
  }
  profilesByItems: ProfilesByItems
  profilesByItemsLoading: boolean
  isLoaded: boolean
  previousAppRouteId: string | null
  prescriptionBackupInfo: any[]
  productApiInfo: Record<string, any> | null
  unsubscribePrescriptionData: FirebaseOnSnapshot | null
  unsubscribeProfiles: FirebaseOnSnapshot | null
  unsubscribecollectionCopied: FirebaseOnSnapshot | null
  unsubscribedestinationCollectionCopied: FirebaseOnSnapshot | null
  unsubscribecollectionToCopy: FirebaseOnSnapshot | null
  unsubscribedestinationCollectionToCopy: FirebaseOnSnapshot | null
  prescriptionData: PrescriptionData
  emptyLoaded: boolean
  destinationCollectionCopied: number | undefined
  collectionCopied: number | undefined
  destinationCollectionToCopy: number | undefined
  collectionToCopy: number | undefined
}

const initialState: PrescriptionState = {
  prescriptionRef: null,
  profiles: [],
  brands: [],
  events: [],
  steps: [],
  missingPrescriptionHits: [],
  items: [],
  looks: [],
  criterias: [],
  flags: [],
  view: {
    id: null,
    events: [],
    steps: [],
  },
  profilesByItems: {},
  profilesByItemsLoading: false,
  isLoaded: false,
  previousAppRouteId: null,
  prescriptionBackupInfo: [],
  productApiInfo: null,
  unsubscribePrescriptionData: null,
  unsubscribeProfiles: null,
  unsubscribecollectionCopied: null,
  unsubscribedestinationCollectionCopied: null,
  unsubscribecollectionToCopy: null,
  unsubscribedestinationCollectionToCopy: null,
  prescriptionData: {
    isPrescriptionDataLoaded: false,
  },
  emptyLoaded: false,
  destinationCollectionCopied: undefined,
  collectionCopied: undefined,
  destinationCollectionToCopy: undefined,
  collectionToCopy: undefined,
}

const state: PrescriptionState = { ...initialState }

type ViewEvent = {
  eventId: string
  stepIds: string[]
}

type ViewStep = {
  id: string
  label: string
  items: StepItem[]
}

type PrescriptionData = {
  meta?: {
    active: boolean
    version: number
    createdAt: FieldValue
    updatedAt: FieldValue
    collectionToCopy: number
    destinationCollectionToCopy: number
  }
  prescriptionType?: string
  computationWorkflowId?: string
  computationStatusInformation?: {
    status: string
    message: string
  }
  isPrescriptionDataLoaded: boolean
}

const mutations: MutationTree<PrescriptionState> = {
  /**
   * Generic mutation.
   *
   * @function setPrescriptionState
   * @param {Object} state - The state of the application.
   * @param {Object} payload - The payload with the property to update and the value.
   */
  setPrescriptionState: (state, payload: { property: string; value: any }) => {
    state[payload.property] = payload.value
  },

  /**
   * Mutation for prescriptionData.
   *
   * @function setPrescriptionState
   * @param {Object} state - The state of the application.
   * @param {Object} payload - The payload with the property to update and the value.
   */
  setPrescriptionDataState: (state, payload: { value: any }) => {
    state.prescriptionData = {
      ...payload.value,
      isPrescriptionDataLoaded: true,
    }
  },

  /**
   * Reset prescription Hits
   *
   * @function resetPrescriptionHits
   * @param {Object} state - The state of the application.
   */
  resetPrescriptionHits: (state) => {
    state.profiles = state.profiles.map((profile) => {
      profile.hit = 0
      return profile
    })
  },

  /**
   * Reset prescription state
   *
   * @function resetPrescriptionState
   * @param {Object} state - The state of the application.
   */
  resetPrescriptionState: (state) => {
    if (state.unsubscribedestinationCollectionCopied)
      state.unsubscribedestinationCollectionCopied()
    if (state.unsubscribecollectionCopied) state.unsubscribecollectionCopied()
    if (state.unsubscribePrescriptionData) state.unsubscribePrescriptionData()
    if (state.unsubscribeProfiles) state.unsubscribeProfiles()
    if (state.unsubscribedestinationCollectionToCopy)
      state.unsubscribedestinationCollectionToCopy()
    if (state.unsubscribecollectionToCopy) state.unsubscribecollectionToCopy()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state = Object.assign(state, initialState)
  },

  addPrescriptionStateValue(
    state,
    { newData, key }: { newData: any; key: string },
  ) {
    if (!state[key] || !newData || !key) return

    state[key].push(newData)
  },

  updatePrescriptionStateValue(
    state,
    { id, newData, key }: { id: string; newData: any; key: string },
  ) {
    if (!state[key] || !newData || !key || !id) return

    const keyIndex = state[key].findIndex((property) => property.id === id)
    state[key].splice(keyIndex, 1, newData)
  },

  updateItemStateValueByIndex(
    state,
    { index, newItem }: { index: number; newItem: any },
  ) {
    state.items.splice(index, 1, newItem)
  },

  deletePrescriptionStateValue(
    state,
    { id, key }: { id: string; key: string },
  ) {
    if (!state[key] || !id || !key) return

    const keyIndex = state[key].findIndex((property) => property.id === id)
    state[key].splice(keyIndex, 1)
  },

  setView(
    state,
    {
      events,
      steps,
      id,
    }: { events: ViewEvent[]; steps: ViewStep[]; id: string },
  ) {
    if (events) {
      state.view.events = events
    }

    if (steps) {
      state.view.steps = steps
    }

    if (id) {
      state.view.id = id
    }
  },

  setPrescriptionLoaded: (state, isLoaded: boolean) => {
    state.isLoaded = isLoaded
  },

  setPrescriptionsBackupsInfo(state, prescriptionBackupInfo: any[]) {
    state.prescriptionBackupInfo = prescriptionBackupInfo
  },
}

const actions: ActionTree<PrescriptionState, RootState> = {
  async setupActivePrescription(
    { commit, state, getters, dispatch },
    {
      currentRouteId,
      loadPrescriptionState = false,
    }: { currentRouteId: string; loadPrescriptionState?: boolean },
  ) {
    if (state.prescriptionData.isPrescriptionDataLoaded) return

    commit('resetPrescriptionState')
    const snapshot = await getFirstActiveDocument(
      'approutes/' + currentRouteId + '/appprescriptions',
    )

    commit('setPrescriptionState', {
      property: 'prescriptionRef',
      value: snapshot.ref,
    })

    const unsubscribePrescriptionData = snapshot.ref.onSnapshot(
      (prescriptionSnapshot) => {
        const prescriptionData = prescriptionSnapshot.data()

        commit('setPrescriptionDataState', { value: prescriptionData })
      },
    )

    commit('setPrescriptionState', {
      property: 'unsubscribePrescriptionData',
      value: unsubscribePrescriptionData,
    })

    const resetOutliers = (counterName: string, valueCounter: number) => {
      if (
        counterName === 'collectionCopied' &&
        !isEmpty(state.collectionToCopy)
      ) {
        return Math.min(valueCounter, state.collectionToCopy)
      }
      if (
        counterName === 'destinationCollectionCopied' &&
        !isEmpty(state.destinationCollectionToCopy)
      ) {
        return Math.min(valueCounter, state.destinationCollectionToCopy)
      }
    }

    const listenCounter = (counterName) => {
      let unsubscribeCounter = null
      unsubscribeCounter = snapshot.ref
        .collection(getCounterCollectionName(counterName))
        .onSnapshot((snapshotCounter) => {
          if (snapshotCounter.empty) {
            commit('setPrescriptionState', {
              property: counterName,
              value: undefined,
            })
            return
          }
          let valueCounter = 0
          snapshotCounter.forEach((doc) => {
            valueCounter += doc.data().count
          })

          valueCounter = resetOutliers(counterName, valueCounter)

          commit('setPrescriptionState', {
            property: counterName,
            value: valueCounter,
          })

          if (!getters.isLocked && !state.isLoaded && loadPrescriptionState) {
            dispatch('initPrescriptionState', { currentRouteId })
          }
        })
      commit('setPrescriptionState', {
        property: 'unsubscribe' + counterName,
        value: unsubscribeCounter,
      })
    }

    listenCounter('collectionToCopy')
    listenCounter('destinationCollectionToCopy')

    listenCounter('collectionCopied')
    listenCounter('destinationCollectionCopied')
  },

  /**
   * Get the active prescription for the current app.
   *
   * @function getActivePrescription
   * @param {Function} commit - The commit function.
   * @param {Object} rootState - The root state of the application.
   */
  async getActivePrescription(
    { commit, rootState, getters, dispatch, state },
    { currentRouteId }: { currentRouteId: string },
  ) {
    if (!currentRouteId) currentRouteId = rootState.approutes.currentRoute.id
    // try {
    await dispatch('setupActivePrescription', {
      currentRouteId,
      loadPrescriptionState: true,
    })

    // if locked wait until prescription is unlock
    if (!getters.isLocked) {
      await dispatch('initPrescriptionState', { currentRouteId })
      // unsubscribe ?
    }

    const unsubscribe = state.prescriptionRef.onSnapshot(
      async (prescription) => {
        const prescriptionData = prescription.data()

        if (
          JSON.stringify(prescriptionData) !==
          JSON.stringify(state.prescriptionData)
        ) {
          if (prescriptionData.meta.active === false) {
            unsubscribe()
            dispatch('getActivePrescription', {
              currentRouteId,
              ignore: true,
            })
            return
          }
          commit('setPrescriptionDataState', { value: prescriptionData })
        }
      },
    )
    // } catch (error) {
    //   throw new Error('No prescription set for this application.')
    // }
  },

  /**
   * Set the prescription state
   *
   * @param {String} currentRouteId - The current route id.
   */
  async initPrescriptionState({ commit, dispatch }, { currentRouteId }) {
    await Promise.all([
      dispatch('getCriterias'),
      dispatch('getProfiles'),
      dispatch('getBrands'),
      dispatch('getEvents'),
      dispatch('getSteps'),
      dispatch('getFlags'),
      dispatch('getMissingPrescriptionHits'),
      dispatch('getDefaultView'),
      dispatch('getItems', {
        currentRouteId,
      }),
    ])

    commit('setPrescriptionLoaded', true)
  },

  /**
   * Get all the elements inside a given collection of a specific prescription.
   * Add them to the right collection state when finished.
   *
   * @function getPrescriptionCollection
   * @param {Object} state - The state of the application.
   * @param {Function} commit - The commit function.
   */
  async getPrescriptionCollection({ getters, commit }, collection: string) {
    const collectionData = await getDocumentsInCollection(
      `${getters.prescriptionPath}/${collection}`,
      true,
    )
    commit('setPrescriptionState', {
      property: collection,
      value: collectionData,
    })
  },

  /**
   * Create a new profile with partial data
   *
   * @function createPartialProfile
   * @param {Object} state - The prescriptions state.
   * @param {Object} partialProfile - the partial profile
   * @return {Promise<Profile>} - Return the newly created profile.
   */
  createPartialProfile: ({ getters }, partialProfile: Record<string, any>) => {
    const newDocRef = db
      .doc(getters.prescriptionPath)
      .collection('profiles')
      .doc()

    const profile = new Profile({
      ...partialProfile,
      id: newDocRef.id,
    })
    auditLog.create(newDocRef.path)
    return newDocRef.withConverter(profileConverter).set(profile)
  },

  /**
   * Toggle visbility of a missing prescription hit
   *
   * @function updateMissingPrescriptionHit
   * @param {Object} state - The prescriptions state.
   * @param {Object} MissingPrescriptionHit
   * @return {Promise<void>}
   */
  updateMissingPrescriptionHit: async (
    { getters },
    { id, ...data }: Record<string, any>,
  ) => {
    return updateRef(
      `${getters.prescriptionPath}/missingprescriptionhits/${id}`,
      data,
    )
  },

  /**
   * Get all the profiles for a specific prescription.
   * Add them to the state 'profiles' when finished.
   * Watch the changes made thanks to firebase onSnapshot method.
   *
   * @function getProfiles
   * @return {Promise} - Return a promise.
   */
  getProfiles({ getters, commit }) {
    const list: Profile[] = []
    const collectionPath = `${getters.prescriptionPath}/profiles`

    const unsubscribeProfiles = db
      .collection(collectionPath)
      .orderBy('isDefault', 'desc')
      .withConverter(profileConverter)
      .onSnapshot((profiles) => {
        profiles.docChanges().forEach((change) => {
          // Formatting profiles from Firestore to be implemented in Firecamp.
          const profile = change.doc.data()
          const profilePos = list.findIndex((x) => x.id == change.doc.id)

          switch (change.type) {
            case 'added':
              if (profilePos === -1) list.push(profile)
              break
            case 'modified':
              list.splice(profilePos, 1, profile)
              break
            case 'removed':
              list.splice(profilePos, 1)
              break
          }
          // Reorder by latest.
          list.sort((a, b) => {
            return +b.meta.createdAt - +a.meta.createdAt
          })
        })

        commit('setPrescriptionState', { property: 'profiles', value: list })
      })

    commit('setPrescriptionState', {
      property: 'unsubscribeProfiles',
      value: unsubscribeProfiles,
    })
  },

  async getEmptySectionsProfiles({ state, commit }) {
    if (state.emptyLoaded) return
    const stepsIds = state.view?.steps?.map((step) => step?.id)
    const profiles = await Promise.all(
      state.profiles.map(async (profile) => {
        const collectionPath = `${profile.ref}/sections`
        const sections = await getDocumentsInCollection(collectionPath)
        const stepsIdsCount = stepsIds.reduce((accumulator, step) => {
          return {
            ...accumulator,
            [step]: 0,
          }
        }, {})
        sections.forEach((section) => {
          const stepSection = section.step
          stepsIdsCount[stepSection]++
        })
        return {
          ...profile,
          withEmptySections: stepsIds.some(
            (stepId) => stepsIdsCount[stepId] === 0,
          ),
        }
      }),
    )

    commit('setPrescriptionState', { property: 'profiles', value: profiles })

    commit('setPrescriptionState', { property: 'emptyLoaded', value: true })
  },

  /**
   * Update data in the prescription document
   *
   * @function updatePrescriptionData
   * @param {Object} state - the state of the application.
   * @param {Function} commit - The commit function.
   * @param {Object} getters - All the getters function.
   */
  async updatePrescriptionData(
    { state, commit, getters },
    { key, value }: { key: string; value: any },
  ) {
    if (!key || !value) return

    await updateRef(getters.prescriptionPath, { [key]: value })

    commit('setPrescriptionState', {
      property: 'prescriptionData',
      value: {
        ...state.prescriptionData,
        [key]: value,
      },
    })
  },

  /**
   * Get the default view for a specific prescription.
   * On one side we create an array of steps that will be stored in 'steps' state.
   * On the other we store the structure of the view in 'events' state to use it afterwards.
   *
   * @function getDefaultView
   * @param {Object} state - the state of the application.
   * @param {Function} commit - The commit function.
   */
  async getDefaultView({ getters, commit }) {
    const views = await db
      .doc(getters.prescriptionPath)
      .collection('views')
      .where('isDefault', '==', true)
      .get()
    if (views.size !== 1) {
      throw {
        code:
          views.size === 0
            ? PRESCRIPTION_ERROR_NO_DEFAULT_VIEW
            : PRESCRIPTION_ERROR_MORE_THAN_ONE_DEFAULT_VIEW,
      }
    }
    const view = views.docs[0].data()
    const events = JSON.parse(view.structure)
    const steps = events
      .map((event) => {
        return event.steps.map((step) => {
          return {
            id: step.stepId,
            label: step.stepId,
            items: [],
          }
        })
      })
      .flat()
    commit('setView', { events, steps, id: views.docs[0].id })
  },

  /**
   * Get all the items for a specific prescription.
   * Depending on the item type we get values in different places.
   * We all get all the looks for a specific brand.
   * Looks are in 'looks' state and Items are in 'items' state.
   *
   * @async
   * @param {Object} state - The state of the application.
   * @param {Function} commit - The commit function.
   * @param {Object} getters - All the getters function.
   * @param {Object} rootGetters - Getters function from other modules.
   */
  async getItems(
    { state, commit, getters, rootGetters, dispatch },
    { currentRouteId }: { currentRouteId: string },
  ) {
    await dispatch('products/loadProductInstance', {
      appRouteId: currentRouteId,
    })
    if (!state.prescriptionRef) return []
    const list = []

    const lookApiInfo = rootGetters.getConfigurationInformations('modifaceApi')

    if (lookApiInfo !== undefined && lookApiInfo !== null) {
      const lookService = new LookService(lookApiInfo)
      const looks = await lookService.getLooksSimple()
      commit('setPrescriptionState', { property: 'looks', value: looks })
    }

    if (rootGetters['products/getProductInstance']) {
      const productLayers = (
        await Functions.getAllProductsForInstance(currentRouteId)
      ).results.filter((pl) => {
        return pl.sku && pl.isActive
      })

      for (let index = 0; index < productLayers.length; index++) {
        const productLayer = productLayers[index]
        const product = new Product(
          `sku_${productLayer.sku}`,
          productLayer.brandCode,
          productLayer.sku,
          productLayer?.overrides?.name || productLayer.name,
          productLayer?.overrides?.packshotImageUrl ||
            productLayer.packshotImageUrl,
        )

        const localItemsState = Array.from(state?.items)
        if (productLayer?.packshotImageUrl && state?.view?.steps?.length > 0) {
          const localStepsState = state.view.steps
          if (localStepsState) commit('setView', { steps: localStepsState })
        }
        commit('setPrescriptionState', {
          property: 'items',
          value: localItemsState,
        })
        list.push(product)
      }
    }

    const productApiInfo =
      rootGetters.getConfigurationInformations('productApi')
    const productService = new ProductService(productApiInfo)
    commit('setPrescriptionState', {
      property: 'productApiInfo',
      value: productApiInfo,
    })
    const items = await db
      .doc(getters.prescriptionPath)
      .collection('items')
      .withConverter(itemConverter)
      .get()

    for (const [index, item] of items.docs.entries()) {
      const itemData = item.data()
      let formatedItem: Record<string, any> = {}
      switch (itemData.type) {
        case ItemType.Product:
          if (rootGetters['products/getProductInstance']) {
            // if product instance is set don't import from items
            continue
          }
          formatedItem = new Product(
            itemData.id,
            itemData.brand,
            itemData.sku,
            itemData.label,
          )
          // Added a timeout to relieve to Product Api.
          setTimeout(() => {
            productService.getProductInfoBySku(itemData).then((product) => {
              if (product === undefined) return
              const doesProductionAlreadyExists = state?.items?.some(
                (item) => item?.sku === product?.sku,
              )
              const localItemsState = Array.from(state?.items)
              const productIndex = doesProductionAlreadyExists
                ? state?.items?.findIndex((item) => item?.sku === product?.sku)
                : index
              localItemsState.splice(productIndex, 1, product)
              // Add image to step already specified.
              if (product?.image && state?.view?.steps?.length > 0) {
                const localStepsState = state.view.steps
                for (const [index, step] of state.view.steps.entries()) {
                  const itemLoaded = step?.items?.find(
                    (item) => item.item === product.item,
                  )
                  if (!itemLoaded) continue
                  itemLoaded.image = product.image ?? ''
                  const itemIndex = step?.items?.findIndex(
                    (item) => item.item === product.item,
                  )
                  localStepsState[index].items.splice(itemIndex, 1, itemLoaded)
                }
                if (localStepsState)
                  commit('setView', { steps: localStepsState })
              }
              commit('setPrescriptionState', {
                property: 'items',
                value: localItemsState,
              })
            })
          }, index * 50)
          break
        case ItemType.Look:
          if (lookApiInfo === undefined && lookApiInfo === null) break
          formatedItem =
            getters.getLookById(itemData?.sku) ??
            new Look({
              sku: itemData?.sku,
              label: itemData?.label,
              brand: itemData?.brand,
            })
          if (formatedItem) formatedItem.brand = itemData?.brand
          break
        case ItemType.Url:
        case ItemType.Content:
          formatedItem = new Item({
            ...(itemData?.content &&
              itemData?.content?.length > 0 && { content: itemData.content }),
            ...(itemData?.url &&
              itemData?.url?.length > 0 && { url: itemData.url }),
            ...(itemData?.label &&
              itemData?.label?.length > 0 && { label: itemData.label }),
            ...(itemData?.description &&
              itemData?.description?.length > 0 && {
                description: itemData.description,
              }),
            item: item?.id,
            type: itemData.type,
          })
          break
      }
      if (
        formatedItem !== undefined &&
        formatedItem !== null &&
        Object.keys(formatedItem).length > 0
      ) {
        list.push(formatedItem)
      }
    }
    commit('setPrescriptionState', { property: 'items', value: list })
  },

  /**
   * Get all the criterias for a specific prescription.
   * They are stored in 'criterias' state.
   *
   * @param {Object} state - The state of the application.
   * @param {Function} commit - The commit function.
   */
  async getCriterias({ getters, commit }, currentRouteId: string) {
    let prescriptionPath = getters.prescriptionPath

    if (currentRouteId) {
      const snapshot = await getFirstActiveDocument(
        'approutes/' + currentRouteId + '/appprescriptions',
      )

      prescriptionPath = snapshot.ref?.path
    }

    if (prescriptionPath) {
      const criterias = await getDocumentsInCollection(
        `${prescriptionPath}/criteria`,
      )
      const sortedCriterias = criterias.sort((a, b) => {
        if (a.order > b.order) return 1
        if (a.order < b.order) return -1
        return 0
      })
      commit('setPrescriptionState', {
        property: 'criterias',
        value: sortedCriterias,
      })
    }
  },

  /**
   * Get all missing prescription hits and their id
   *
   * @param {Object} state - The state of the application.
   * @param {Function} commit - The commit function.
   */
  getMissingPrescriptionHits({ getters, commit }) {
    const list = []

    return new Promise((resolve) => {
      db.doc(getters.prescriptionPath)
        .collection('missingprescriptionhits')
        .onSnapshot((hits) => {
          hits.docChanges().forEach(({ type, doc }) => {
            const index = list.findIndex((x) => x.id === doc.id)

            switch (type) {
              case 'added':
                list.push(doc)
                break
              case 'modified':
                list.splice(index, 1, doc)
                break
              case 'removed':
                list.splice(index, 1)
                break
            }
          })

          const newValue = list.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))

          commit('setPrescriptionState', {
            property: 'missingPrescriptionHits',
            value: newValue,
          })
          resolve(newValue)
        })
    })
  },

  /**
   * Get all the flags for a specific prescription.
   * They are stored in 'flags' state.
   *
   * @param {Function} dispatch- The dispatch function.
   */
  async getFlags({ dispatch }) {
    await dispatch('getPrescriptionCollection', 'flags')
  },

  /**
   * Get all the brands for a specific prescription.
   * They are stored in 'brands' state.
   *
   * @param {Function} dispatch- The dispatch function.
   */
  async getBrands({ dispatch }) {
    await dispatch('getPrescriptionCollection', 'brands')
  },

  /**
   * Get all the events for a specific prescription.
   * They are stored in 'events' state.
   *
   * @param {Function} dispatch- The dispatch function.
   */
  async getEvents({ dispatch }) {
    await dispatch('getPrescriptionCollection', 'events')
  },

  /**
   * Get all the steps for a specific prescription.
   * They are stored in 'steps' state.
   *
   * @param {Function} dispatch- The dispatch function.
   */
  async getSteps({ dispatch }) {
    await dispatch('getPrescriptionCollection', 'steps')
  },

  /**
   * Get all the infos about the prescriptions in the parent collection
   */
  async getPrescriptionsBackupsInfo({ commit, rootState }) {
    const collectionPath =
      'approutes/' + rootState.approutes.currentRoute.id + '/appprescriptions'
    const collectionSnapshot = await db
      .collection(collectionPath)
      .where('meta.active', '==', false)
      .get()

    const options = await Promise.all(
      collectionSnapshot?.docs?.map(async (prescription) => {
        const prescriptionData = prescription?.data()
        return {
          path: `${collectionPath}/${prescription?.id}`,
          id: `${prescription?.id} ${prescriptionData?.meta?.updatedAt
            ?.toDate()
            .toLocaleString()}`,
        }
      }),
    )

    commit('setPrescriptionsBackupsInfo', options)
  },

  async setEventStep({ dispatch, getters, state }, editedEventsSteps: any[]) {
    const structure = await Promise.all(
      editedEventsSteps.map(async (event) => {
        if (event.isChanged || event.isNew) {
          await updateRef(
            getters.prescriptionPath + '/events/' + event.id,
            {
              label: event.label,
              ...(event.matchings && { matchings: event.matchings }),
            },
            true,
          )
        }
        const steps = await Promise.all(
          event.steps.map(async (step) => {
            if (step.isChanged || step.isNew) {
              await updateRef(
                getters.prescriptionPath + '/steps/' + step.id,
                {
                  label: step.label,
                  ...(step.matchings && { matchings: step.matchings }),
                },
                true,
              )
            }
            return { stepId: step.id }
          }),
        )

        return {
          eventId: event.id,
          steps,
        }
      }),
    )

    await updateRef(getters.prescriptionPath + '/views/' + state.view.id, {
      structure: JSON.stringify(structure),
    })
    await Promise.all([
      dispatch('getEvents'),
      dispatch('getSteps'),
      dispatch('getDefaultView'),
    ])
  },

  updateItemBySku({ state, commit }, newItem) {
    if (!newItem || !newItem.sku) return

    const itemIndex = state.items.findIndex((item) => item.sku === newItem.sku)
    commit('updateItemStateValueByIndex', { itemIndex, newItem })
  },

  async resetProfilesHit({ commit, rootState }) {
    await Functions.resetProfilesHit(rootState.approutes.currentRoute.id)
    commit('resetPrescriptionHits')
  },

  /**
   * Flush Product API cache.
   * @param {Object} state
   * @return {Promise<void>}
   */
  async flushProductApi({ state }) {
    if (!state.productApiInfo) return
    const productService = new ProductService(state.productApiInfo)
    return productService.flushCache()
  },

  deleteAllProfilesFromState({ commit }) {
    commit('setPrescriptionState', { property: 'profiles', value: [] })
  },

  async setProfilesByItems(
    { rootState, commit },
    { appRouteId, force = false }: { appRouteId: string; force: boolean },
  ) {
    if (!appRouteId || (state.previousAppRouteId === appRouteId && !force)) {
      return
    }
    // Set loading
    commit('setPrescriptionState', {
      property: 'profilesByItemsLoading',
      value: true,
    })

    // Get and Set the profilesByItems
    const profilesByItems = await Functions.getProfilesByItems(
      appRouteId ?? rootState.approutes.currentRoute.id,
    )
    commit('setPrescriptionState', {
      property: 'profilesByItems',
      value: profilesByItems,
    })

    // Set the previous route
    commit('setPrescriptionState', {
      property: 'previousAppRouteId',
      value: appRouteId,
    })

    // Stop the loading
    commit('setPrescriptionState', {
      property: 'profilesByItemsLoading',
      value: false,
    })
  },

  resetPreviousAppRouteId({ commit }) {
    commit('setPrescriptionState', {
      property: 'previousAppRouteId',
      value: null,
    })
  },
}

const getters: GetterTree<PrescriptionState, RootState> = {
  getItemById: (state) => (id: string) => {
    return state.items.find(({ item }) => item === id)
  },

  getItemsByType:
    (state) =>
    (type: ItemType | ItemType[]): Item[] => {
      if (Array.isArray(type)) {
        return state.items.filter((item) => type.includes(item?.type))
      }
      return state.items.filter((item) => item?.type === type)
    },

  /**
   * Get a step by it's id.
   *
   * @param {Object} state - The state of the application.
   * @param {String} id - An id of step.
   * @return {Object} Return a step.
   */
  getStepById: (state) => (id: string) => {
    const step = state.view.steps?.find((step) => step.id === id)
    return step
  },

  /**
   * Get step data by it's id.
   * @param {*} state
   * @returns
   */
  getStepDataById: (state) => (id: string) => {
    const step = state.steps?.find((step) => step.id === id)
    return step
  },

  /**
   * Get a event by it's id.
   *
   * @param {Object} state - The state of the application.
   * @param {String} id - An id of step.
   * @return {Object} Return a step.
   */
  getEventById: (state) => (id: string) => {
    const event = state.events?.find((event) => event.id === id)
    return event
  },

  /**
   * Get event label by it's id.
   * @param {*} state
   * @returns
   */
  getEventLabelById: (state) => (id: string) => {
    const event = state.events?.find((event) => event.id === id)
    return event?.label
  },

  /**
   * Get a look by it's id.
   *
   * @param {Object} state - The state of the application.
   * @param {String} id - An id of look.
   * @return {Object} Return a look.
   */
  getLookById: (state) => (id: string) => {
    return state.looks?.find((look) => look.sku === id)
  },

  /**
   * Get a profile by it's id.
   *
   * @param {Object} state - The state of the application.
   * @param {String} id - An id of profile.
   * @return {Object} Return a profile.
   */
  getProfileById: (state) => (id: string) => {
    return state.profiles.find((profile) => profile.id === id)
  },

  /**
   * Returns the state profiles where the given item id is used based on the profilesByItems property
   *
   * @param {Object} state - The state of the application.
   * @param {String} itemId - The id of an item.
   * @return {Object} Return an array of profile.
   */
  getProfilesByItemId:
    (state, getters) =>
    (itemId: string): Profile[] => {
      if (
        !itemId ||
        !state.isLoaded ||
        !state.profilesByItems ||
        !(state.profiles?.length > 0 || getters.getItemById(itemId))
      ) {
        return []
      }

      const profilesInfos = state.profilesByItems[itemId] ?? []
      const profiles = profilesInfos.reduce((acc, profileInfos) => {
        const profileFound = state.profiles.find(
          (stateProfile) => stateProfile.id === profileInfos.profileId,
        )
        if (profileFound) {
          acc.push(profileFound)
        }
        return acc
      }, [])

      return profiles ?? []
    },

  /**
   * Get a criteria by it's id.
   *
   * @param {Object} state - The state of the application.
   * @param {String} criteriaId - An id of criteria.
   * @return {Object} Return a criteria.
   */
  getCriteriaById: (state) => (criteriaId: string) => {
    return (
      state?.criterias?.find((criteria) => criteria?.id === criteriaId) ?? {
        label: criteriaId,
        id: criteriaId,
        item: criteriaId,
      }
    )
  },

  /**
   * Format items data to match teh required format for multiselect.
   *
   * @param {Object} state - The state of the application.
   * @return {Object} Return an array of criterias.
   */
  itemsAsCriterias: (state) => {
    return state?.items?.map((item) => {
      if (!item?.type && !item?.sku) return {}
      return {
        ...(item.color && { color: item.color }),
        label: `${item.label} ${item.sku ? ` - ${item.sku}` : ''}`,
        id: `${item.type}:${item.sku}`,
        item: item.item,
      }
    })
  },

  /**
   * Get all the applications with the prescription enabled.
   *
   * @function getAppsWithPrescription
   * @return {Array} The array of applications.
   */
  getAppsWithPrescription: (state, getters, rootState, rootGetters) => {
    if (
      !rootState?.applications ||
      rootState?.appversions?.activeVersions?.length === 0
    )
      return []

    const apps = rootState.applications
    const appsWithPrescription = apps
      .map((app) => {
        const appversions = rootGetters.getAppVersions(app?.id)
        const appversionsWithPrescription = appversions?.filter(
          (version) => version?.prescription,
        )
        if (appversionsWithPrescription?.length > 0) {
          return app
        }
      })
      .filter(Boolean)
    return appsWithPrescription
  },

  /**
   * Returns the default profiles of the prescription of the current route in the state.
   *
   * @param {Object} state - The state of the application.
   * @returns {Array} The default profiles.
   */
  getDefaultProfiles: (state) => {
    if (!state.isLoaded || !state.prescriptionRef) return null

    return state.profiles?.filter(
      (profile) => profile.isDefault && profile.meta?.active,
    )
  },

  criteriasCustom: (state, _getters, rootState) => {
    if (!state.isLoaded || !state.prescriptionRef) return []

    return state.criterias?.filter(
      (criteria) =>
        !rootState.criterias?.find(
          (criteriaMaster) => criteriaMaster.id === criteria.id,
        ),
    )
  },

  criteriasNotCustom: (state, _getters, rootState) => {
    if (!state.isLoaded || !state.prescriptionRef) return []

    return state.criterias?.filter((criteria) =>
      rootState.criterias?.find(
        (criteriaMaster) => criteriaMaster.id === criteria.id,
      ),
    )
  },

  /**
   * Check if prescription is locked.
   * @param {Object} state
   * @returns
   */
  isLocked: (state, getters) => {
    const meta = state.prescriptionData?.meta
    if (!meta) return false
    return (
      meta.active === false ||
      getters.isPrescriptionCopyRunning ||
      getters.isAIComputationRunning
    )
  },

  isPrescriptionCopyRunning: (state) => {
    const meta = state.prescriptionData?.meta
    return (
      meta.active === false ||
      (state.collectionCopied &&
        state.collectionCopied !== state.collectionToCopy) ||
      (state.destinationCollectionCopied &&
        state.destinationCollectionCopied !== state.destinationCollectionToCopy)
    )
  },

  isAIComputationRunning: (state, getters) => {
    return (
      getters.isAIPoweredPrescription &&
      !!state.prescriptionData.computationWorkflowId
    )
  },

  isPrescriptionLoaded: (state) => state.isLoaded,

  progressCopy: (state) => {
    const meta = state.prescriptionData?.meta
    if (!meta) return 0
    const progress =
      state.collectionCopied !== state.collectionToCopy
        ? (state.collectionCopied / state.collectionToCopy) * 100
        : (state.destinationCollectionCopied /
            state.destinationCollectionToCopy) *
          100

    if (progress > 90 && progress < 100) return 90

    return progress || 0
  },

  isAIPoweredPrescription: (state) => {
    return (
      state.prescriptionData?.prescriptionType === PRESCRIPTION_TYPES.AI_POWERED
    )
  },

  /**
   * Check if the prescription accepts items profile criteria
   */
  acceptItemsProfileCriteria: (_state, _getters, _rootState, rootGetters) => {
    return !!rootGetters.getApplication?.allowedItemProfilePrescription
  },

  prescriptionPath: (state) => {
    return state.prescriptionRef?.path
  },

  // PROFILES
  profilesTotalHit: (state) => {
    return state.profiles.reduce((acc, pro) => acc + pro.hit, 0)
  },

  criteriasByProfiles: (state) => {
    return state.criterias.map((criteria) => {
      for (const type of [
        'inclusiveCriterias',
        'inclusiveStrictCriterias',
        'exclusiveCriterias',
      ]) {
        const profiles = state.profiles.filter((profile) =>
          profile[type]?.includes(criteria.id),
        )
        criteria['count' + type] = profiles.length
        criteria[type] = profiles
      }
      return criteria
    })
  },

  criteriasCoverage: (state, getters) => {
    const count = getters.criteriasByProfiles.reduce(
      (acc, { countinclusiveCriterias }) => acc + (countinclusiveCriterias > 0),
      0,
    )

    return count / getters.criteriasByProfiles.length
  },

  getLockedProfiles: (state) => {
    if (!state.profiles) return []
    return state.profiles.filter((profile) => profile.isLocked)
  },

  /**
   * Check if the prescription has brands configured (used for prescription automation).
   * @return Return on object with valid true if there is at least one brand and value the number of brands
   */
  getBrandsValidation: (state) => {
    const value = state.brands?.length ?? 0
    const valid = value > 0
    return { valid, value }
  },

  /**
   * Check if the prescription has product items configured (used for prescription automation).
   * @return Return on object with valid true if there is at least one product item and value the products
   */
  getProductAPIValidation: (state) => {
    if (!state.items || state.items.length < 1)
      return { valid: false, value: [] }
    const value = state.items.filter((item) => item.type === 'product')

    return { valid: value.length > 0, value }
  },

  /**
   * Check if the prescription has criteria configured (used for prescription automation).
   * @return Return on object with valid true if there is at least one criteria and value the criterias
   */
  getCriteriaValidation: (state) => {
    const value = state.criterias
    return { valid: value?.length > 0, value }
  },

  /**
   * Check if the prescription has a valid routine structure (used for prescription automation).
   * @return Return on object with valid true if the routine is valid and value the routine
   */
  getRoutineStructureValidation: (state) => {
    if (!state.view) return { valid: false, value: [] }
    const value = state.view.events ?? []
    const valid =
      value.length > 0 &&
      !value.some((event) => !event.steps || event.steps.length < 1)
    return { valid, value }
  },

  /**
   * Check if the prescription has locked profiles (used for prescription automation)
   * @return Return on object with valid always true  and value the locked profiles
   */
  getLockedProfilesValidation: (state, getters) => {
    const value = getters.getLockedProfiles
    return { valid: true, value }
  },

  /**
   * Returns the profilesByItemsLoading property
   * @return Return profilesByItemsLoading value
   */
  getProfilesByItemsLoading: (state) => {
    return state.profilesByItemsLoading
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
