import partition from 'lodash/fp/partition'
import isNil from 'lodash/fp/isNil'
import negate from 'lodash/fp/negate'
import get from 'lodash/fp/get'
import Functions, {
  PrivateProductDetails,
  SearchProductsPagination,
  SearchProductsParams,
} from '@/firebase/functions'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { RootState } from '@/store'
import {
  BHProductDTO,
  ProductStats,
  ProductStatsValue,
} from '@/dtos/BHProduct.dto'
import { parseAppRouteId } from '@/utils/InstanceHelper'
import { CalibrationStatus } from '@/constants/CalibrationStatus.enum'
import { Profile } from '@/types/Profile'
import { BeautyHubEnv, BeautyHubTenant } from '@/firebase/schemas'

enum ConfigValueType {
  Link = 'link',
  Image = 'image',
  Tags = 'tags',
  Default = 'default',
  Boolean = 'boolean',
  Number = 'number',
  Array = 'array',
}

export type IndexedStatsValue = {
  yes: number
  no: number
}

export type BHCalibrationStatus = {
  isActive: boolean
  exists: boolean
  isValidated: boolean
  skip: boolean
  isActiveWithouCalibration: boolean
}

export type FormattedProduct = {
  preview: string | null
  sku: {
    route: {
      name: string
      params: {
        id?: string
        sku?: string
      }
    }
    label: string
  }
  id: string
  label: string
  calibration: string | null
  errors: string
  price: string | null
  pdpUrl: {
    label: string
    url: string
  }
  heroIngredients: any[]
  flags: any[]
  profilesLinked: Profile[]
  debugInfo: Record<string, any>
}

export type ProductInstance = {
  id: string
  customerCode: string
  countryCode: string
  service: string
  touchpoint: string
  type: string
  customIdentifier: string | null
  catalogConnector: {
    id: string
    name: string
    apiUri: string
    query: string | null
    template: string
    customerCode: string
    countryCode: string
    langCode: string | null
    customIdentifier: string | null
    createdAt: string
  }
  fallbackOnCustomerAPI: boolean
  allowUnselectedProduct: boolean
  createdAt: string
}

export type CatalogConnector = {
  id: string
  name: string
  apiUri: string
  query: string
  authenticationType: string
  template: string
  syncStatus: string
  syncStatusUpdatedAt: string
  productSyncInterval: string
  workingHoursStart: string
  workingHoursEnd: string
  maxConcurrentFetch: number
  delayBetweenFetch: string
  customerCode: string
  countryCode: string
  langCode: string
  createdAt: string
  customIdentifier: string
  firecampData: {
    lastDeployToProd: string
    lastDeployToProdBy: string
    lastUpdate: string
    lastUpdateBy: string
  }
}

export type InstanceProducts = {
  sku: string
  brandCode: string
  name: string
  packshotImageUrl: string
  status: string
  statusUpdatedAt: string
  productLayerCreatedAt: string
  isActive: boolean
  forceNextSync: boolean
  lastSyncEndedAt: string
  lastSyncStartedAt: string
  calibration: {
    isActive: boolean
    isValidated: boolean
    exists: boolean
  }
  overrides: {
    name: string
    brandCode: string
    packshotImageUrl: string
  }
}

export type ProductsFacets = {
  key: string
  values: {
    count: number
    value: any
  }[]
}

export type ProductsState = {
  isLoading: boolean
  isStatsLoading: boolean
  isProductInstanceLoading: boolean
  isCatalogConnectorLoading: boolean
  previousSearchParams?: SearchProductsParams
  previousAppRouteId: string | null
  previousAllProductsAppRouteId: string | null
  products: any[]
  pagination: SearchProductsPagination
  catalogPagination: SearchProductsPagination
  indexedStats: {
    isFound: IndexedStatsValue
    isExist: IndexedStatsValue
    isCalibrated: IndexedStatsValue
    isActiveWithoutCalibration: IndexedStatsValue
  }
  detailsConfigLoadedForAppRouteId: string | null
  productSettings: Record<any, string> | null
  productDetails: PrivateProductDetails | null
  allProductsForSearchAndReplace: any[]
  productInstance: ProductInstance | null
  catalogConnector: CatalogConnector | null
  catalogConnectorProducts: BHProductDTO[]
  productsv3: InstanceProducts[]
  facets: ProductsFacets[]
  catalogConnectorFacets: ProductsFacets[]
  productDetailsv3: Record<string, any>
  catalogProduct: Record<string, any>
}

const defaultIndexedStatsValue = {
  yes: 0,
  no: 0,
}

const defaultPagination = {
  nbResults: 0,
  nbPages: 0,
  currentPage: 0,
}

const defaultIndexedStats = {
  isFound: defaultIndexedStatsValue,
  isCalibrated: defaultIndexedStatsValue,
  isActiveWithoutCalibration: defaultIndexedStatsValue,
  isExist: defaultIndexedStatsValue,
}

const indexedStatsLabelSuccess = {
  isFound: 'found',
  isCalibrated: 'Calibrated',
  isActiveWithoutCalibration: 'Live',
  isExist: 'Found',
}

const initialState: ProductsState = {
  isLoading: false,
  isStatsLoading: false,
  isProductInstanceLoading: true,
  isCatalogConnectorLoading: false,
  previousSearchParams: null,
  previousAppRouteId: null,
  previousAllProductsAppRouteId: null,
  products: [],
  pagination: defaultPagination,
  catalogPagination: defaultPagination,
  indexedStats: defaultIndexedStats,
  detailsConfigLoadedForAppRouteId: null,
  productSettings: null,
  productDetails: null,
  allProductsForSearchAndReplace: [],
  productInstance: null,
  catalogConnector: null,
  catalogConnectorProducts: [],
  productsv3: [],
  facets: [],
  catalogConnectorFacets: [],
  productDetailsv3: null,
  catalogProduct: null,
}

type Maybe<T> = T | null | undefined

const isDefined = negate(isNil)

type ConfigValue = {
  label: string
  key?: string
  type?: string
  isEditable?: boolean | string
  isVisible?: boolean | string
  getValueFn?: (value: Record<string, any>) => any
  transformValueFn?: (value: any) => any
}

const mapKeyDataConfig =
  (configValues: ConfigValue[]) => (productDetails: PrivateProductDetails) => {
    const { merged, overriden } = productDetails

    return configValues.map((c) => {
      const { key, getValueFn, transformValueFn } = c
      const getValue = getValueFn || get(key)
      const value = getValue(merged)

      return {
        ...c,
        value: transformValueFn ? transformValueFn(value) : value,
        isHighlighted: isDefined(getValue(overriden)),
      }
    })
  }

const getEditVisibleValue = (
  configValue: string | boolean,
  productInstance,
  catalogConnector,
): boolean => {
  if (typeof configValue === 'boolean') return configValue
  if (typeof configValue === 'string') {
    if (configValue === 'onlyInstance') {
      return !!productInstance
    } else if (configValue === 'withBrandApiOnly') {
      return (
        (!!productInstance &&
          productInstance?.catalogConnector?.template !== 'Local') ||
        (catalogConnector && catalogConnector?.template !== 'Local')
      )
    } else if (configValue === 'instanceOrWithoutBrandApi') {
      return (
        !!productInstance || (catalogConnector && !catalogConnector?.apiUri)
      )
    } else if (configValue === 'withoutBrandApiOnly') {
      return (
        (!!productInstance &&
          productInstance?.catalogConnector?.template === 'Local') ||
        (catalogConnector && catalogConnector?.template === 'Local')
      )
    }
  }
}

const mapKeyDataConfigv3 =
  (configValues: ConfigValue[]) =>
  (
    productDetails: any = null,
    catalogProduct: any,
    productInstance,
    catalogConnector,
  ) => {
    return configValues.map((c) => {
      const { key, getValueFn, transformValueFn } = c
      const getValue = getValueFn || get(key)
      const value = productDetails
        ? getValue(productDetails)
        : getValue(catalogProduct)
      const catalogValue = getValue(catalogProduct)

      return {
        ...c,
        isEditable: getEditVisibleValue(
          c.isEditable,
          productInstance,
          catalogConnector,
        ),
        isVisible: getEditVisibleValue(
          c.isVisible,
          productInstance,
          catalogConnector,
        ),
        value: transformValueFn ? transformValueFn(value) : value,
        catalogValue: transformValueFn
          ? transformValueFn(catalogValue)
          : catalogValue,
      }
    })
  }

const transformBooleanToString = (value: Maybe<boolean>): Maybe<string> => {
  if (typeof value === 'boolean') return value ? 'Yes' : 'No'
  return value
}

const transformArrayStringsToString = (
  elements: Maybe<any[]>,
): Maybe<string> => {
  if (isNil(elements)) return elements
  return elements.join(', ')
}

const v2ProductDetailsConfig = {
  generalProductCol1Config: [
    {
      key: 'sku',
      label: 'EAN / SKU',
    },
    {
      key: 'name',
      label: 'Name',
      isEditable: false,
    },
    {
      key: 'originalName',
      label: 'Original name',
    },
    {
      key: 'subtitle',
      label: 'Subtitle',
      isEditable: false,
    },
    {
      key: 'active',
      label: 'Active',
    },
    {
      key: 'isNew',
      label: 'Is new',
      isEditable: false,
      transformValueFn: transformBooleanToString,
    },
    {
      label: 'Service available',
      getValueFn: (merged) => merged?.services?.some((s) => s.available),
    },
    {
      key: 'franchise',
      label: 'Franchise',
      isEditable: false,
    },
    {
      key: 'category',
      label: 'Category',
      isEditable: false,
    },
    {
      key: 'family',
      label: 'Family',
      isEditable: false,
    },
    {
      key: 'collection',
      label: 'Collection',
      isEditable: false,
    },
    {
      key: 'finish',
      label: 'Finish',
      isEditable: false,
    },
    {
      key: 'hexValue',
      label: 'Hex value',
      isEditable: false,
    },
    {
      key: 'claim',
      label: 'Claim',
      isEditable: false,
    },
  ],
  generalProductCol2Config: [
    {
      key: 'packshotImageUrl',
      label: 'Packshot image',
      type: 'image',
    },
    {
      key: 'packshotImageUrl',
      label: 'Packshot URL',
      type: 'link',
      isEditable: false,
    },
    {
      key: 'pdpUrl',
      label: 'pdp URL',
      isEditable: false,
      type: 'link',
    },
    {
      key: 'brandCode',
      label: 'Brand',
      isEditable: false,
    },
    {
      key: 'brandLabel',
      label: 'Brand label',
    },
    {
      key: 'countryCode',
      label: 'Country code',
    },
    {
      key: 'priceWithoutDiscount',
      label: 'Price without discount',
      isEditable: false,
    },
    {
      key: 'rating',
      label: 'Rating',
    },
    {
      key: 'rating_Count',
      label: 'Rating count',
    },
  ],
  generalProductBottomConfig: [
    {
      key: 'shortDescription',
      label: 'Description',
      isEditable: false,
    },
    {
      key: 'heroIngredients',
      label: 'Hero ingredients',
      isEditable: false,
      transformValueFn: transformArrayStringsToString,
    },
  ],
  ecommerceCol1Config: [
    {
      key: 'eCommerce.priceWithoutDiscount',
      label: 'Price without discount',
      isEditable: false,
    },
    {
      key: 'eCommerce.price',
      label: 'Price',
    },
    {
      key: 'eCommerce.currency',
      label: 'Currency',
    },
    {
      key: 'eCommerce.size',
      label: 'Size',
    },
    {
      key: 'eCommerce.color',
      label: 'Color',
      isEditable: false,
    },
    {
      key: 'eCommerce.enabled', // TODO: check which field it is
      label: 'Ecommerce enabled',
      isEditable: false,
    },
  ],
  ecommerceCol2Config: [
    {
      key: 'eCommerce.orderable',
      label: 'Orderable',
      transformValueFn: transformBooleanToString,
    },
    {
      key: 'eCommerce.availability',
      label: 'Availability',
    },
    {
      key: 'eCommerce.outOfStock',
      label: 'Out of stock',
    },
    {
      key: 'eCommerce.clicktobuyUrl',
      label: 'Click to buy URL',
      isEditable: false,
    },
    {
      key: 'addToCartSku',
      label: 'Add to cart SKU',
    },
  ],
  analyticsCol1Config: [
    {
      key: 'analytics.originalName',
      label: 'Original name',
    },
    {
      key: 'analytics.productMasterId',
      label: 'Product master id',
    },
    {
      key: 'analytics.brandAnaltyticsTrigram',
      label: 'Brand analytics trigram',
    },
    {
      key: 'analytics.productCategory',
      label: 'Product category',
    },
  ],
  analyticsCol2Config: [
    {
      key: 'analytics.productVariant',
      label: 'Product variant',
    },
    {
      key: 'analytics.outOfStock',
      label: 'Out of stock',
    },
    {
      key: 'analytics.serviceEngraving',
      label: 'Service engraving',
    },
    {
      key: 'analytics.serviceVirtualtryon',
      label: 'Service virtual try on',
    },
  ],
  variationsConfig: [
    {
      key: 'samplerUrl',
      label: 'Sampler URL',
      isEditable: false,
      type: 'link',
    },
  ],
  flagsConfig: [
    {
      key: 'flags',
      label: 'Flags',
      isEditable: false,
      type: 'tags',
      getValueFn: (merged) => {
        const flags = merged.flags
        if (!Array.isArray(flags)) return flags

        return flags.map((flag) => ({
          label: flag,
          color: 'primary',
        }))
      },
    },
    {
      key: 'commercialFlags',
      label: 'Commercial Flags',
      isEditable: false,
      type: 'tags',
      getValueFn: (merged) => {
        const flags = merged.commercialFlags
        if (!Array.isArray(flags)) return flags

        return flags.map((flag) => ({
          label: flag,
          color: 'secondary',
        }))
      },
    },
  ],
  virtualtryonCol1Config: [
    {
      key: 'modifaceVTOUpcName',
      label: 'Modiface VTO Upc Name',
      isEditable: false,
    },
    {
      key: 'shadeName',
      label: 'Shade',
      isEditable: false,
    },
    {
      key: 'multiProductDisabled', // TODO: check which field it is
      label: 'Multi Product Disabled',
      transformValueFn: transformBooleanToString,
    },
    {
      key: 'colorMatchEnabled', // TODO: check which field it is
      label: 'Color match enabled',
      transformValueFn: transformBooleanToString,
    },
    {
      key: 'category',
      label: 'Category',
    },
  ],
  virtualtryonCol2Config: [
    {
      key: 'shadeImagePath',
      label: 'Shade image',
      type: 'image',
    },
    {
      key: 'shadeImagePath',
      label: 'Shade image path',
      type: 'link',
      isEditable: false,
    },
  ],
  modifaceCmsCalibrationConfig: [
    {
      key: 'modifaceCms.calibrationStatus.exists',
      label: 'Exist',
      transformValueFn: transformBooleanToString,
    },
    {
      key: 'modifaceCms.calibrationStatus.isActive',
      label: 'Active',
      transformValueFn: transformBooleanToString,
    },
    {
      key: 'modifaceCms.calibrationStatus.isValidated',
      label: 'Validated',
      transformValueFn: transformBooleanToString,
    },
  ],
  modifaceCmsPigmentationConfig: [
    {
      key: 'modifaceCms.pigmentation.reduction',
      label: 'Reduction',
    },
    {
      key: 'modifaceCms.pigmentation.threshold',
      label: 'Threshold',
    },
  ],
}

const v2Mappers = {
  mapKeyDataGeneralProductCol1Config: mapKeyDataConfig(
    v2ProductDetailsConfig.generalProductCol1Config,
  ),
  mapKeyDataGeneralProductCol2Config: mapKeyDataConfig(
    v2ProductDetailsConfig.generalProductCol2Config,
  ),
  mapKeyDataGeneralProductBottomConfig: mapKeyDataConfig(
    v2ProductDetailsConfig.generalProductBottomConfig,
  ),
  mapKeyDataEcommerceCol1Config: mapKeyDataConfig(
    v2ProductDetailsConfig.ecommerceCol1Config,
  ),
  mapKeyDataEcommerceCol2Config: mapKeyDataConfig(
    v2ProductDetailsConfig.ecommerceCol2Config,
  ),
  mapKeyDataAnalyticsCol1Config: mapKeyDataConfig(
    v2ProductDetailsConfig.analyticsCol1Config,
  ),
  mapKeyDataAnalyticsCol2Config: mapKeyDataConfig(
    v2ProductDetailsConfig.analyticsCol2Config,
  ),
  mapKeyDataVariationsConfig: mapKeyDataConfig(
    v2ProductDetailsConfig.variationsConfig,
  ),
  mapKeyDataFlagsConfig: mapKeyDataConfig(v2ProductDetailsConfig.flagsConfig),
  mapKeyDataVirtualtryonCol1Config: mapKeyDataConfig(
    v2ProductDetailsConfig.virtualtryonCol1Config,
  ),
  mapKeyDataVirtualtryonCol2Config: mapKeyDataConfig(
    v2ProductDetailsConfig.virtualtryonCol2Config,
  ),
  mapKeyDataModifaceCmsCalibrationConfig: mapKeyDataConfig(
    v2ProductDetailsConfig.modifaceCmsCalibrationConfig,
  ),
  mapKeyDataModifaceCmsPigmentationConfig: mapKeyDataConfig(
    v2ProductDetailsConfig.modifaceCmsPigmentationConfig,
  ),
}

const v3ProductDetailsConfig = {
  generalProductCol1Config: [
    {
      key: 'sku',
      label: 'EAN / SKU',
      isEditable: false,
      isVisible: true,
    },
    {
      key: 'originalName',
      label: 'Original name',
      isEditable: false,
      isVisible: 'withBrandApiOnly',
    },
    {
      key: 'subTitle',
      label: 'Subtitle',
      isEditable: 'withoutBrandApiOnly',
      isVisible: true,
    },
    {
      key: 'name',
      label: 'Name',
      isEditable: 'instanceOrWithoutBrandApi',
      isVisible: true,
    },
    {
      key: 'pdpUrl',
      label: 'Pdp URL',
      type: ConfigValueType.Link,
      isEditable: 'instanceOrWithoutBrandApi',
      isVisible: 'withBrandApiOnly',
    },
    {
      key: 'rangeCode',
      label: 'Range code',
      isEditable: 'instanceOrWithoutBrandApi',
      isVisible: 'withBrandApiOnly',
    },
    {
      key: 'countryCode',
      label: 'country code',
      isEditable: false,
      isVisible: 'withBrandApiOnly',
    },
    {
      key: 'rating',
      label: 'Rating',
      isEditable: false,
      isVisible: 'withBrandApiOnly',
    },
    {
      key: 'ratingCount',
      label: 'Rating count',
      isEditable: false,
      isVisible: 'withBrandApiOnly',
    },
    {
      key: 'daysOfUsage',
      label: 'Days of usage',
      isEditable: 'onlyInstance',
      isVisible: 'withBrandApiOnly',
    },

    {
      key: 'addToCartSku',
      label: 'Add to cart SKU',
      isEditable: false,
      isVisible: 'withBrandApiOnly',
    },

    {
      key: 'family',
      label: 'Family',
      isEditable: 'instanceOrWithoutBrandApi',
      isVisible: true,
    },
  ],
  generalProductCol2Config: [
    {
      key: 'productClaim',
      label: 'Product Claim',
      isEditable: 'onlyInstance',
      isVisible: 'withBrandApiOnly',
    },
    {
      key: 'brandCode',
      label: 'Brand Code',
      isEditable: 'instanceOrWithoutBrandApi',
      isVisible: true,
    },
    {
      key: 'brandLabel',
      label: 'Brand Label',
      isEditable: 'withoutBrandApiOnly',
      isVisible: true,
    },
    {
      key: 'packshotImageUrl',
      label: 'Packshot Image URL',
      type: ConfigValueType.Image,
      isEditable: 'instanceOrWithoutBrandApi',
      isVisible: true,
    },
    {
      key: 'finish',
      label: 'Finish',
      isEditable: 'instanceOrWithoutBrandApi',
      isVisible: true,
    },
    {
      key: 'collection',
      label: 'Collection',
      isEditable: 'withoutBrandApiOnly',
      isVisible: true,
    },
    {
      key: 'franchise',
      label: 'Franchise',
      isEditable: 'instanceOrWithoutBrandApi',
      isVisible: true,
    },
    {
      key: 'hexValue',
      label: 'Hex Value',
      isEditable: 'instanceOrWithoutBrandApi',
      isVisible: true,
    },
    {
      key: 'isNew',
      label: 'is New',
      type: ConfigValueType.Boolean,
      isEditable: 'onlyInstance',
      isVisible: 'withBrandApiOnly',
    },
    {
      key: 'category',
      label: 'Category',
      isEditable: 'onlyInstance',
      isVisible: 'withBrandApiOnly',
    },
    {
      key: 'disableForMP',
      label: 'Disable for MP',
      type: ConfigValueType.Boolean,
      isEditable: 'onlyInstance',
      isVisible: 'withBrandApiOnly',
    },
  ],
  generalProductBottomConfig: [
    {
      key: 'shortDescription',
      label: 'Short Description',
      isEditable: 'instanceOrWithoutBrandApi',
      isVisible: true,
    },
    {
      key: 'heroIngredients',
      label: 'Hero ingredients',
      type: ConfigValueType.Tags,
      isEditable: 'onlyInstance',
      isVisible: 'withBrandApiOnly',
    },
    {
      key: 'services',
      label: 'Services',
      type: ConfigValueType.Array,
      isEditable: false,
      isVisible: 'withBrandApiOnly',
    },
  ],
  virtualtryonCol1Config: [
    {
      key: 'modifaceVTOUpcName',
      label: 'Modiface VTO Upc Name',
      isEditable: 'onlyInstance',
      isVisible: 'withBrandApiOnly',
    },
    {
      key: 'shadeImagePath',
      label: 'Shade Image Path',
      type: ConfigValueType.Link,
      isEditable: 'instanceOrWithoutBrandApi',
      isVisible: true,
    },
    {
      key: 'shade',
      label: 'Shade',
      isEditable: 'instanceOrWithoutBrandApi',
      isVisible: true,
    },
    {
      key: 'shadeName',
      label: 'Shade Name',
      isEditable: 'instanceOrWithoutBrandApi',
      isVisible: true,
    },
  ],
  variationsConfig: [
    {
      key: 'samplerUrl',
      label: 'Sampler URL',
      type: ConfigValueType.Link,
      isEditable: 'onlyInstance',
      isVisible: 'withBrandApiOnly',
    },
  ],
  flagsConfig: [
    {
      key: 'flags',
      label: 'Flags',
      type: ConfigValueType.Tags,
      isEditable: 'onlyInstance',
      isVisible: 'onlyInstance',
    },
    {
      key: 'commercialFlags',
      label: 'Commercial Flags',
      type: ConfigValueType.Tags,
      isEditable: 'onlyInstance',
      isVisible: 'onlyInstance',
    },
  ],
  ecommerceCol1Config: [
    {
      key: 'eCommerce.clickToBuyUrl',
      label: 'Click To Buy URL',
      isEditable: 'instanceOrWithoutBrandApi',
      isVisible: true,
    },
    {
      key: 'eCommerce.priceWithoutDiscount',
      type: ConfigValueType.Number,
      label: 'Price Without Discount',
      isEditable: false,
      isVisible: 'withBrandApiOnly',
    },
    {
      key: 'eCommerce.color',
      label: 'Color',
      isEditable: 'instanceOrWithoutBrandApi',
      isVisible: true,
    },
    {
      key: 'eCommerce.price',
      label: 'Price',
      isEditable: false,
      isVisible: 'withBrandApiOnly',
    },
    {
      key: 'eCommerce.currency',
      label: 'Currency',
      isEditable: false,
      isVisible: 'withBrandApiOnly',
    },
  ],
  ecommerceCol2Config: [
    {
      key: 'eCommerce.orderable',
      label: 'Orderable',
      isEditable: false,
      isVisible: 'withBrandApiOnly',
    },
    {
      key: 'eCommerce.size',
      label: 'Size',
      isEditable: false,
      isVisible: 'withBrandApiOnly',
    },
    {
      key: 'eCommerce.availability',
      label: 'Availability',
      isEditable: false,
      isVisible: 'withBrandApiOnly',
    },
    {
      key: 'eCommerce.outOfStock',
      label: 'Out Of Stock',
      isEditable: false,
      isVisible: 'withBrandApiOnly',
    },
  ],
  analyticsCol1Config: [
    {
      key: 'analytics.productMasterId',
      label: 'Analytics Product Master ID',
      isEditable: false,
      isVisible: 'withBrandApiOnly',
    },
    {
      key: 'analytics.productCategory',
      label: 'Analytics Product Category',
      isEditable: false,
      isVisible: 'withBrandApiOnly',
    },
    {
      key: 'analytics.brandAnalyticsTrigram',
      label: 'Analytics Brand Analytics Trigram',
      isEditable: false,
      isVisible: 'withBrandApiOnly',
    },
    {
      key: 'analytics.productVariant',
      label: 'Analytics Product Variant',
      isEditable: false,
      isVisible: 'withBrandApiOnly',
    },
  ],
  analyticsCol2Config: [
    {
      key: 'analytics.outOfStock',
      label: 'Analytics Out Of Stock',
      isEditable: false,
      isVisible: 'withBrandApiOnly',
    },
    {
      key: 'analytics.serviceEngraving',
      label: 'Analytics Service Engraving',
      isEditable: false,
      isVisible: 'withBrandApiOnly',
    },
    {
      key: 'analytics.serviceVirtualTryOn',
      label: 'Analytics Service Virtual Try On',
      isEditable: false,
      isVisible: 'withBrandApiOnly',
    },
  ],
  modifaceCmsCalibrationConfig: [
    {
      key: 'modifaceCMSData.calibrationStatus.exists',
      label: 'Exists',
      type: ConfigValueType.Boolean,
      isEditable: false,
      isVisible: 'withBrandApiOnly',
    },
    {
      key: 'modifaceCMSData.calibrationStatus.isActive',
      label: 'Active',
      isEditable: false,
      isVisible: 'withBrandApiOnly',
    },
    {
      key: 'modifaceCMSData.calibrationStatus.isValidated',
      label: 'Validated',
      isEditable: false,
      isVisible: 'withBrandApiOnly',
    },
    {
      key: 'modifaceCMSData.calibration.isActiveWithouCalibration',
      label: 'Is active without calibration',
      type: ConfigValueType.Boolean,
      isEditable: false,
      isVisible: 'withBrandApiOnly',
    },
  ],
  modifaceCmsPigmentationConfig: [
    {
      key: 'modifaceCMSData.pigmentation.reduction',
      label: 'Reduction',
      isEditable: false,
      isVisible: 'withBrandApiOnly',
    },
    {
      key: 'modifaceCMSData.pigmentation.threshold',
      label: 'Threshold',
      isEditable: false,
      isVisible: 'withBrandApiOnly',
    },
  ],
}

const v3Mappers = {
  mapKeyDataGeneralProductCol1Config: mapKeyDataConfigv3(
    v3ProductDetailsConfig.generalProductCol1Config,
  ),
  mapKeyDataGeneralProductCol2Config: mapKeyDataConfigv3(
    v3ProductDetailsConfig.generalProductCol2Config,
  ),
  mapKeyDataGeneralProductBottomConfig: mapKeyDataConfigv3(
    v3ProductDetailsConfig.generalProductBottomConfig,
  ),
  mapKeyDataEcommerceCol1Config: mapKeyDataConfigv3(
    v3ProductDetailsConfig.ecommerceCol1Config,
  ),
  mapKeyDataEcommerceCol2Config: mapKeyDataConfigv3(
    v3ProductDetailsConfig.ecommerceCol2Config,
  ),
  mapKeyDataAnalyticsCol1Config: mapKeyDataConfigv3(
    v3ProductDetailsConfig.analyticsCol1Config,
  ),
  mapKeyDataAnalyticsCol2Config: mapKeyDataConfigv3(
    v3ProductDetailsConfig.analyticsCol2Config,
  ),
  mapKeyDataVariationsConfig: mapKeyDataConfigv3(
    v3ProductDetailsConfig.variationsConfig,
  ),
  mapKeyDataFlagsConfig: mapKeyDataConfigv3(v3ProductDetailsConfig.flagsConfig),
  mapKeyDataVirtualtryonCol1Config: mapKeyDataConfigv3(
    v3ProductDetailsConfig.virtualtryonCol1Config,
  ),
  // mapKeyDataVirtualtryonCol2Config: mapKeyDataConfigv3(
  //   v3ProductDetailsConfig.virtualtryonCol2Config,
  // ),
  mapKeyDataModifaceCmsCalibrationConfig: mapKeyDataConfigv3(
    v3ProductDetailsConfig.modifaceCmsCalibrationConfig,
  ),
  mapKeyDataModifaceCmsPigmentationConfig: mapKeyDataConfigv3(
    v3ProductDetailsConfig.modifaceCmsPigmentationConfig,
  ),
}

const findStatWithName = (label: string) => (stat: ProductStatsValue) =>
  label === stat.name

const state = { ...initialState }

const mutations: MutationTree<ProductsState> = {
  SET_LOADING: (state, isLoading: boolean) => {
    state.isLoading = isLoading
  },

  SET_STATS_LOADING: (state, isLoading: boolean) => {
    state.isStatsLoading = isLoading
  },

  SET_PRODUCTINSTANCE_LOADING: (state, isLoading: boolean) => {
    state.isProductInstanceLoading = isLoading
  },

  SET_CATALOG_CONNECTOR_LOADING: (state, isLoading: boolean) => {
    state.isCatalogConnectorLoading = isLoading
  },

  SET_CATALOG_PRODUCTS: (
    state,
    payload: {
      catalogConnectorProducts: BHProductDTO[]
      pagination: SearchProductsPagination
    },
  ) => {
    state.catalogConnectorProducts = payload.catalogConnectorProducts
    state.catalogPagination = payload.pagination
  },

  SET_CATALOG_FACETS: (state, facets: ProductsFacets[]) => {
    state.catalogConnectorFacets = facets
  },

  SET_PRODUCTINSTANCE_FACETS: (state, facets: ProductsFacets[]) => {
    state.facets = facets
  },

  SET_PREVIOUS_APP_ROUTE_ID: (state, appRouteId: string) => {
    state.previousAppRouteId = appRouteId
  },

  SET_PREVIOUS_ALL_PRODUCTS_APP_ROUTE_ID: (state, appRouteId: string) => {
    state.previousAllProductsAppRouteId = appRouteId
  },

  SET_SEARCH_PARAMS: (state, payload: SearchProductsParams) => {
    state.previousSearchParams = payload
  },

  SET_PRODUCTS: (
    state,
    payload: { products: any[]; pagination: SearchProductsPagination },
  ) => {
    state.products = payload.products
    state.pagination = payload.pagination
  },

  SET_PRODUCTSV3: (
    state,
    payload: { products: any[]; pagination: SearchProductsPagination },
  ) => {
    state.productsv3 = payload.products
    state.pagination = payload.pagination
  },

  SET_PRODUCTINSTANCE: (
    state,
    payload: { productInstance: ProductInstance },
  ) => {
    state.productInstance = payload.productInstance
  },

  SET_CATALOG_CONNECTOR: (
    state,
    payload: { catalogConnector: CatalogConnector },
  ) => {
    state.catalogConnector = payload.catalogConnector
  },

  SET_STATS: (state, payload: ProductStats) => {
    const [[found], [notFound]] = partition(
      findStatWithName(indexedStatsLabelSuccess.isFound),
    )(payload.productFounds)
    const [[calibrated], [notCalibrated]] = partition(
      findStatWithName(indexedStatsLabelSuccess.isCalibrated),
    )(payload.productModifaceCalibrated)
    const [[liveCalibrated], [liveNotCalibrated]] = partition(
      findStatWithName(indexedStatsLabelSuccess.isActiveWithoutCalibration),
    )(payload.productModifaceCalibratedLive)
    const [[modifaceExists], [modifaceMissing]] = partition(
      findStatWithName(indexedStatsLabelSuccess.isExist),
    )(payload.productModifaceExists)

    state.indexedStats = {
      isFound: {
        yes: found?.score || 0,
        no: notFound?.score || 0,
      },
      isCalibrated: {
        yes: calibrated?.score || 0,
        no: notCalibrated?.score || 0,
      },
      isActiveWithoutCalibration: {
        yes: liveCalibrated?.score || 0,
        no: liveNotCalibrated?.score || 0,
      },
      isExist: {
        yes: modifaceExists?.score || 0,
        no: modifaceMissing?.score || 0,
      },
    }
  },

  SET_DETAILS_CONFIG_LOADED_FOR_APP_ROUTE_ID: (state, appRouteId: string) => {
    state.detailsConfigLoadedForAppRouteId = appRouteId
  },

  SET_ALL_PRODUCTS: (state, products: any[]) => {
    state.allProductsForSearchAndReplace = products
  },

  RESET_STATE: (state) => {
    const currentState = state
    state = Object.assign(currentState, initialState)
  },
}

const actions: ActionTree<ProductsState, RootState> = {
  async loadProductInstance(
    { commit, rootState },
    { appRouteId }: { appRouteId: string; searchParams: SearchProductsParams },
  ) {
    commit('SET_PRODUCTINSTANCE_LOADING', true)
    const productInstanceId =
      rootState.approutes.currentRoute.data.appconfigurations.productApi
        ?.catalogConfiguration?.productInstanceId

    if (productInstanceId) {
      const productInstance = await Functions.getProductInstance(appRouteId)
      if (productInstance.id) {
        commit('SET_PRODUCTINSTANCE', { productInstance })
      }
    }
    console.log('productinstance loaded')
    commit('SET_PRODUCTINSTANCE_LOADING', false)
  },
  async searchProducts(
    { commit },
    {
      appRouteId,
      searchParams,
    }: { appRouteId: string; searchParams: SearchProductsParams },
  ) {
    const shouldRefreshStats = state.previousAppRouteId !== appRouteId

    commit('SET_LOADING', true)
    if (shouldRefreshStats) commit('SET_STATS_LOADING', true)

    if (state.productInstance) {
      const {
        results: products,
        pagination,
        facets,
      } = await Functions.searchProductsV3(appRouteId, searchParams)

      commit('SET_PRODUCTINSTANCE_FACETS', { facets })
      commit('SET_PRODUCTSV3', { products, pagination })
    } else {
      const [{ results: products, pagination }, stats] = await Promise.all([
        Functions.searchProducts(appRouteId, searchParams),
        shouldRefreshStats ? Functions.getProductsStats(appRouteId) : undefined,
      ])
      commit('SET_PRODUCTS', { products, pagination })
      if (stats) {
        commit('SET_STATS', stats)
        commit('SET_STATS_LOADING', false)
      }
    }
    commit('SET_PREVIOUS_APP_ROUTE_ID', appRouteId)
    commit('SET_SEARCH_PARAMS', searchParams)
    commit('SET_LOADING', false)
  },

  async setAllProducts({ commit }, appRouteId: string) {
    const shouldRefreshAllProducts =
      state.previousAllProductsAppRouteId !== appRouteId

    if (!shouldRefreshAllProducts) {
      return
    }

    commit('SET_LOADING', true)

    const [{ results: products }] = await Promise.all([
      Functions.searchProducts(appRouteId, {}),
    ])
    commit('SET_ALL_PRODUCTS', products)
    commit('SET_PREVIOUS_ALL_PRODUCTS_APP_ROUTE_ID', appRouteId)

    commit('SET_LOADING', false)
  },

  async loadProductDetails(
    { state, commit, dispatch },
    {
      appRouteId,
      productId,
      routeName,
    }: { appRouteId: string; productId: string; routeName: string },
  ) {
    const shouldLoadConfiguration = Boolean(
      !state.detailsConfigLoadedForAppRouteId ||
        state.detailsConfigLoadedForAppRouteId !== appRouteId,
    )

    if (shouldLoadConfiguration) {
      await dispatch(
        'setCurrentRouteById',
        { currentRouteId: appRouteId, routeName },
        { root: true },
      )
    }
    await Promise.all([
      shouldLoadConfiguration
        ? dispatch('getProductSettings', { appRouteId })
        : undefined,
      dispatch('getProductDetails', {
        appRouteId,
        sku: productId,
      }),
    ])

    if (shouldLoadConfiguration) {
      commit('SET_DETAILS_CONFIG_LOADED_FOR_APP_ROUTE_ID', appRouteId)
    }
  },

  async loadProductDetailsV3(
    { state, commit },
    { appRouteId, productId }: { appRouteId: string; productId: string },
  ) {
    const shouldLoadConfiguration = Boolean(
      !state.detailsConfigLoadedForAppRouteId ||
        state.detailsConfigLoadedForAppRouteId !== appRouteId,
    )

    if (shouldLoadConfiguration) {
      // await dispatch('setCurrentRouteById', appRouteId, { root: true })
    }

    const { productLayer, catalogProduct } =
      await Functions.getProductDetailsV3(appRouteId, productId)

    state.productDetailsv3 = productLayer
    state.catalogProduct = catalogProduct

    if (shouldLoadConfiguration) {
      commit('SET_DETAILS_CONFIG_LOADED_FOR_APP_ROUTE_ID', appRouteId)
    }
  },

  async getProductSettings(
    { rootState },
    { appRouteId }: { appRouteId: string },
  ) {
    const productApiConfig =
      rootState.approutes.currentRoute.data.appconfigurations?.productApi
    const { application, environment } = parseAppRouteId(appRouteId)
    const allowedEnvironments = ['staging', 'production']
    const defaultApplication = 'defaultService'

    if (
      !productApiConfig?.params ||
      !allowedEnvironments.includes(environment)
    ) {
      state.productSettings = null
      return
    }

    const { brand_code: brandCode, country_code: countryCode } =
      productApiConfig.params

    const { configuration } = await Functions.getProductConfiguration(
      environment as 'staging' | 'production',
      brandCode,
      countryCode,
    )

    const matchedAirtable =
      configuration.airtableUriList.find(
        ({ serviceName }) => serviceName === application,
      ) ||
      configuration.airtableUriList.find(
        ({ serviceName }) => serviceName === defaultApplication,
      )

    state.productSettings = {
      template: configuration.template,
      brandApiUrl: configuration.apiUri,
      airtableUrl: matchedAirtable?.url,
      productApiUrl: productApiConfig.url,
    }
  },

  async loadCatalogConnector(
    { commit },
    {
      catalogConnectorId,
      tenant,
    }: {
      catalogConnectorId: string
      env: BeautyHubEnv
      tenant: BeautyHubTenant
    },
  ) {
    commit('SET_CATALOG_CONNECTOR_LOADING', true)
    const catalogConnector = await Functions.getCatalogById(
      catalogConnectorId,
      tenant,
    )
    commit('SET_CATALOG_CONNECTOR', { catalogConnector })
    commit('SET_CATALOG_CONNECTOR_LOADING', false)
  },

  async searchCatalogProducts(
    { commit },
    {
      catalogConnectorId,
      tenant,
      searchParams,
    }: {
      catalogConnectorId: string
      tenant: BeautyHubTenant
      searchParams: SearchProductsParams
    },
  ) {
    commit('SET_LOADING', true)
    // if (shouldRefreshStats) commit('SET_STATS_LOADING', true)

    const { items, pagination, facets } = await Functions.getProductsByCatalog(
      catalogConnectorId,
      tenant,
      searchParams,
    )

    // commit('SET_PREVIOUS_APP_ROUTE_ID', appRouteId)
    // commit('SET_CATALOG_SEARCH_PARAMS', searchParams)
    commit('SET_CATALOG_PRODUCTS', {
      catalogConnectorProducts: items,
      pagination,
    })
    commit('SET_CATALOG_FACETS', facets)

    commit('SET_LOADING', false)
  },

  async getProductDetails(
    _,
    { appRouteId, sku }: { appRouteId: string; sku: string },
  ) {
    const productDetails = await Functions.getPrivateProductDetails(
      appRouteId,
      sku,
    )

    state.productDetails = productDetails
  },

  async getCatalogProductDetails(
    _,
    props: {
      tenant: BeautyHubTenant
      catalogConnectorId: string
      sku: string
    },
  ) {
    const { tenant, catalogConnectorId, sku } = props
    const res = await Functions.getProductFromCatalog(
      tenant,
      catalogConnectorId,
      sku,
    )
    state.catalogProduct = res
  },

  unbindProducts({ commit }) {
    commit('RESET_STATE')
  },
}

const getters: GetterTree<ProductsState, RootState> = {
  getProductInstance: (state) => {
    return state.productInstance
  },

  getCatalogConnector: (state) => {
    return state.catalogConnector
  },

  catalogConnectorLoading: (state) => {
    return state.isCatalogConnectorLoading
  },

  isProductInstanceLoading: (state) => {
    return state.isProductInstanceLoading
  },

  withBrandApi: (state) => {
    return state.productInstance
      ? state.productInstance?.catalogConnector?.apiUri &&
          state.productInstance?.catalogConnector?.template !== 'Local'
      : state.catalogConnector?.apiUri &&
          state.catalogConnector?.template !== 'Local'
  },

  catalogConnectorId: (state) => {
    return state.productInstance?.catalogConnector?.id
  },

  products: (state) => {
    return state.productInstance ? state.productsv3 : state.products
  },

  facets: (state) => {
    return state.facets
  },

  catalogConnectorProducts: (state) => {
    return state.catalogConnectorProducts
  },

  catalogPagination: (state) => {
    return state.catalogPagination
  },

  catalogFacets: (state) => {
    return state.catalogConnectorFacets
  },

  getProductLayers: (state) => {
    const { productDetailsv3 } = state

    return productDetailsv3 || {}
  },

  getCatalogProduct: (state) => {
    const { catalogProduct } = state
    return catalogProduct || {}
  },

  getAllProducts: (state) => {
    return state.allProductsForSearchAndReplace ?? []
  },

  productSettingsKeyValues: (state) => {
    const { productSettings } = state
    if (!productSettings) return []

    return [
      {
        key: 'template',
        label: 'Template',
        value: productSettings.template,
      },
      {
        key: 'brandApiUrl',
        label: 'Brand API',
        value: productSettings.brandApiUrl,
        type: 'link',
      },
      {
        key: 'airtableUrl',
        label: 'Airtable',
        value: productSettings.airtableUrl,
        type: 'link',
      },
      {
        key: 'productApiUrl',
        label: 'Product API',
        value: productSettings.productApiUrl,
        type: 'link',
      },
    ]
  },

  generalDataCol1KeyValues: (state) => {
    const {
      productInstance,
      productDetails,
      productDetailsv3,
      catalogProduct,
      catalogConnector,
    } = state

    if (productInstance || catalogConnector) {
      return v3Mappers.mapKeyDataGeneralProductCol1Config(
        productDetailsv3,
        catalogProduct,
        productInstance,
        catalogConnector,
      )
    } else {
      if (!productDetails) return []

      return v2Mappers.mapKeyDataGeneralProductCol1Config(productDetails)
    }
  },

  generalDataCol2KeyValues: (state) => {
    const {
      productInstance,
      productDetails,
      productDetailsv3,
      catalogProduct,
      catalogConnector,
    } = state

    if (productInstance || catalogConnector) {
      return v3Mappers.mapKeyDataGeneralProductCol2Config(
        productDetailsv3,
        catalogProduct,
        productInstance,
        catalogConnector,
      )
    } else {
      if (!productDetails) return []

      return v2Mappers.mapKeyDataGeneralProductCol2Config(productDetails)
    }
  },

  generalDataBottomKeyValues: (state) => {
    const {
      productInstance,
      productDetails,
      productDetailsv3,
      catalogProduct,
      catalogConnector,
    } = state

    if (productInstance || catalogConnector) {
      return v3Mappers.mapKeyDataGeneralProductBottomConfig(
        productDetailsv3,
        catalogProduct,
        productInstance,
        catalogConnector,
      )
    } else {
      if (!productDetails) return []

      return v2Mappers.mapKeyDataGeneralProductBottomConfig(productDetails)
    }
  },

  ecommerceCol1KeyValues: (state) => {
    const {
      productInstance,
      productDetails,
      productDetailsv3,
      catalogProduct,
      catalogConnector,
    } = state

    if (productInstance || catalogConnector) {
      return v3Mappers.mapKeyDataEcommerceCol1Config(
        productDetailsv3,
        catalogProduct,
        productInstance,
        catalogConnector,
      )
    } else {
      if (!productDetails) return []

      return v2Mappers.mapKeyDataEcommerceCol1Config(productDetails)
    }
  },

  ecommerceCol2KeyValues: (state) => {
    const {
      productInstance,
      productDetails,
      productDetailsv3,
      catalogProduct,
      catalogConnector,
    } = state

    if (productInstance || catalogConnector) {
      return v3Mappers.mapKeyDataEcommerceCol2Config(
        productDetailsv3,
        catalogProduct,
        productInstance,
        catalogConnector,
      )
    } else {
      if (!productDetails) return []

      return v2Mappers.mapKeyDataEcommerceCol2Config(productDetails)
    }
  },

  analyticsCol1KeyValues: (state) => {
    const {
      productInstance,
      productDetails,
      productDetailsv3,
      catalogProduct,
      catalogConnector,
    } = state

    if (productInstance || catalogConnector) {
      return v3Mappers.mapKeyDataAnalyticsCol1Config(
        productDetailsv3,
        catalogProduct,
        productInstance,
        catalogConnector,
      )
    } else {
      if (!productDetails) return []

      return v2Mappers.mapKeyDataAnalyticsCol1Config(productDetails)
    }
  },

  analyticsCol2KeyValues: (state) => {
    const {
      productInstance,
      productDetails,
      productDetailsv3,
      catalogProduct,
      catalogConnector,
    } = state

    if (productInstance || catalogConnector) {
      return v3Mappers.mapKeyDataAnalyticsCol2Config(
        productDetailsv3,
        catalogProduct,
        productInstance,
        catalogConnector,
      )
    } else {
      if (!productDetails) return []

      return v2Mappers.mapKeyDataAnalyticsCol2Config(productDetails)
    }
  },

  variationsKeyValues: (state) => {
    const {
      productInstance,
      productDetails,
      productDetailsv3,
      catalogProduct,
      catalogConnector,
    } = state

    if (productInstance || catalogConnector) {
      return v3Mappers.mapKeyDataVariationsConfig(
        productDetailsv3,
        catalogProduct,
        productInstance,
        catalogConnector,
      )
    } else {
      if (!productDetails) return []

      return v2Mappers.mapKeyDataVariationsConfig(productDetails)
    }
  },

  variationItems: (state) => {
    const {
      productInstance,
      productDetails,
      catalogProduct,
      catalogConnector,
    } = state

    if (productInstance || catalogConnector) {
      if (!catalogProduct?.variations) return []

      return catalogProduct?.variations?.flatMap((variation) => {
        const { sku, variationValues } = variation

        return variationValues.map(({ hexaCode, image }) => {
          return {
            image,
            keyValues: [
              {
                label: 'EAN / SKU',
                value: sku,
              },
              {
                label: 'hexaCode',
                value: hexaCode,
              },
            ],
          }
        })
      })
    } else {
      if (!productDetails || !Array.isArray(productDetails.merged?.variations))
        return []
      return productDetails.merged.variations.flatMap((variation) => {
        const { sku, variationValues } = variation

        return variationValues.map(({ hexaCode, image }) => {
          return {
            image,
            keyValues: [
              {
                label: 'EAN / SKU',
                value: sku,
              },
              {
                label: 'hexaCode',
                value: hexaCode,
              },
            ],
          }
        })
      })
    }
  },

  flagsKeyValues: (state) => {
    const {
      productInstance,
      productDetails,
      productDetailsv3,
      catalogProduct,
      catalogConnector,
    } = state

    if (productInstance || catalogConnector) {
      return v3Mappers.mapKeyDataFlagsConfig(
        productDetailsv3,
        catalogProduct,
        productInstance,
        catalogConnector,
      )
    } else {
      if (!productDetails) return []

      return v2Mappers.mapKeyDataFlagsConfig(productDetails)
    }
  },

  virtualtryonCol1KeyValues: (state) => {
    const {
      productInstance,
      productDetails,
      productDetailsv3,
      catalogProduct,
      catalogConnector,
    } = state

    if (productInstance || catalogConnector) {
      return v3Mappers.mapKeyDataVirtualtryonCol1Config(
        productDetailsv3,
        catalogProduct,
        productInstance,
        catalogConnector,
      )
    } else {
      if (!productDetails) return []

      return v2Mappers.mapKeyDataVirtualtryonCol1Config(productDetails)
    }
  },

  virtualtryonCol2KeyValues: (state) => {
    const { productInstance, productDetails, catalogConnector } = state

    if (productInstance || catalogConnector) {
      return []
    } else {
      if (!productDetails) return []

      return v2Mappers.mapKeyDataVirtualtryonCol2Config(productDetails)
    }
  },

  modifaceCmsCalibrationKeyValues: (state) => {
    const {
      productInstance,
      productDetails,
      productDetailsv3,
      catalogProduct,
      catalogConnector,
    } = state

    if (productInstance || catalogConnector) {
      return v3Mappers.mapKeyDataModifaceCmsCalibrationConfig(
        productDetailsv3,
        catalogProduct,
        productInstance,
        catalogConnector,
      )
    } else {
      if (!productDetails) return []

      return v2Mappers.mapKeyDataModifaceCmsCalibrationConfig(productDetails)
    }
  },

  modifaceCmsPigmentationKeyValues: (state) => {
    const {
      productInstance,
      productDetails,
      productDetailsv3,
      catalogProduct,
      catalogConnector,
    } = state

    if (productInstance || catalogConnector) {
      return v3Mappers.mapKeyDataModifaceCmsPigmentationConfig(
        productDetailsv3,
        catalogProduct,
        productInstance,
        catalogConnector,
      )
    } else {
      if (!productDetails) return []

      return v2Mappers.mapKeyDataModifaceCmsPigmentationConfig(productDetails)
    }
  },

  getCalibrationStatusValue:
    () => (calibrationStatus?: BHCalibrationStatus) => {
      if (!calibrationStatus || calibrationStatus?.skip)
        return CalibrationStatus.NotApplicable
      else if (calibrationStatus?.isValidated)
        return CalibrationStatus.Calibrated
      else if (calibrationStatus?.isActive)
        return CalibrationStatus.InProductionWithoutCalibration
      else if (calibrationStatus?.exists) return CalibrationStatus.NotCalibrated
      else return CalibrationStatus.Missing
    },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
